.spinner__holder {
    width: 30px;
    height: 20px;
}

.spinner__thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid;
    border-color: #fff;
    border-top-color: transparent;
    animation: spin 0.9s linear infinite;
}

.spinner__holder.dark .spinner__thumb{
    border-color: #0D1CB3;
     border-top-color: transparent;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
