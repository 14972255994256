.lay__holder {
  min-height: 100vh;
}

.sideBar {
  padding: 1.5rem 0;
  width: 100px;
  position: fixed;
  height: 100vh;
  background-color: #0d1cb3;
}

.react-tabs__tab--selected {
  outline: none;
}

.react-tabs__tab--selected::before {
  opacity: 1 !important;
}

.link__holder {
  position: relative;
}

.link__holder::before {
  opacity: 0;
}

.tab__button li {
  list-style: none;
}

.tab__button li {
  box-shadow: inset 0 0 10px #b7b7b7;
  border-radius: 8px;
  background-color: #f8f9fa;
  padding: 1rem 2.5rem;
  text-align: center;
  font-size: 21px;
  border: 1px solid #b7b7b7;
  min-width: 50px;
  cursor: pointer;
}

.tab__button .react-tabs__tab--selected {
  background: linear-gradient(90deg, #0d1cb3 0%, #3eb9e5 110%) 0 0 no-repeat
    padding-box;
  color: #fff;
}
.tab__button .react-tabs__tab--selected:hover {
  background: linear-gradient(90deg, #3eb9e5 0%, #0d1cb3 110%) 0 0 no-repeat
    padding-box;
}

.link__holder::before,
.sideBar .react-tabs__tab--selected::before {
  content: "";
  background-color: #fff;
  width: 7px;
  height: 100%;
  position: absolute;
  left: 0;
  border-radius: 0 5px 5px 0;
  transition: all 0.25s ease;
}
.link__holder:hover::before {
  opacity: 1;
}

.lay__content {
  margin-left: 100px;
}

.nav {
  padding: 1em 0;
}

.nav__links {
  min-width: 35%;
  justify-content: end;
}

.search {
  min-width: 30%;
}

.profile__img {
  background-color: #b7b7b740;
  width: 55px;
  height: 55px;
  border: 1px solid #b7b7b740;
  border-radius: 50%;
  overflow: hidden;
}
.profile__img img {
  width: 100%;
}

.icon__holder {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.table__actionBtn {
  border: 1px solid #70707085;
  border-radius: 6px;
  padding: 8px;
  color: #707070;
  cursor: pointer;
}

.field__holder {
  border: 1px solid #b7b7b7;
  border-radius: 15px;
  display: flex;
  overflow: hidden;
}
.field__holder.fit {
  width: 40%;
}

.field__title {
  background-color: #fff;
  width: 25%;
  border-right: 1px solid #b7b7b7;
  overflow: hidden;
  padding: 1rem;
}

.field__info {
  background-color: #f8f9fa;
  padding: 1rem 2rem;
  width: 80%;
}

.tab__button button {
  box-shadow: inset 0 0 10px #b7b7b7;
  border-radius: 8px;
}

.label {
  height: 15px;
  width: 15px;
}
.label.black {
  background-color: #000000;
}
.label.mustard {
  background-color: #ff9100;
}
.label.danger {
  background-color: #ff0000;
}

.modal__custom .modal-dialog {
  min-width: 900px;
}
.modal__custom.lg .modal-dialog {
  min-width: 1350px;
}

.doc__preview {
  background-color: #f0f2f7;
  min-height: 35vh;
  border-radius: 8px;
}

.icon__close {
  position: absolute;
  right: 2rem;
  top: 1.5rem;
}

.input__fee {
  border: 1px solid #b7b7b7;
  background-color: #f8f9fa;
  border-radius: 15px;
  padding: 0.8rem;
}

.tag {
  width: 120px;
  padding: 0.3rem 1.5rem;
  text-align: center;
  border-radius: 6px;
  color: #fff;
}
.tag.active {
  background-color: #18c489;
}
.tag.inactive {
  background-color: #db1b28;
}

.logo__imgHolder {
  width: 80px;
  margin: 0 auto;
}

.asset__logo {
  border-radius: 6px;
  background-color: #f6f6f6;
  width: 90px;
  height: 100%;
  padding: 3px;
}
.asset__logo img {
  width: 60px;
  margin: 0 auto;
}

.dropdown {
  background-color: #f6f6f6;
  padding: 0.5rem;
  border-radius: 5px;
}
.dropdown.outline {
  background-color: #ffffff;
  border: 1px solid #b7b7b7;
}
.dropdown .dropdown i {
  position: relative;
  right: 0;
  transition: all 0.4s ease-in-out;
}
.dropdown i.flipped {
  transform: rotate(180deg);
}
.options {
  position: absolute;
  height: 140px;
  background-color: #ffff;
  border-radius: 6px;
  min-width: 150px;
  overflow-y: scroll;
  border: 1px solid #707070;
  box-shadow: 0 3px 15px #00000029;
  margin-top: 0.3rem;
  padding: 0.6rem;
  z-index: 9999;
}
.option {
  transition: all 0.3s ease;
  cursor: pointer;
  padding: 0.8rem 0.6rem;
}
.option:hover {
  background-color: #f6f6f6;
}
.option .radio {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #707070;
}
.checkbox__custom {
  width: 20px;
  height: 20px;
  background-color: #f6f6f6;
  border-radius: 5px;
}
.option .radio.filled .checkbox__custom.filled {
  background: #0d1cb3;
  border: none;
}
.radio.filled.green {
  background-color: #9ddb06;
}
.radio.filled.blue {
  background-color: #23bdde;
}

.dropdown__holder .backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
}

.popover {
  min-width: 250px;
  height: fit-content;
  padding: 13px;
  border: 1px solid #707070;
  box-shadow: 0 3px 15px #00000029;
  background-color: #fff;
}

.switcher__option {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 7vh;
  width: 50%;
  margin: 0 20px 0 0;
  background-color: #f6f6f6;
  border-radius: 5px;
  color: #000;
}
.switcher__option.active {
  background-color: #4236e4;
  color: #fff;
}

.tab__custom {
  display: flex;
}
.tab__custom li {
  list-style: none;
  font-size: 20px;
  color: #d3d3d3;
  font-weight: bold;
  border-bottom: 1px solid;
  width: 50%;
  text-align: center;
  padding: 1rem;
  cursor: pointer;
}
.tab__custom .react-tabs__tab--selected {
  border-bottom: 5px solid #4236e4;
  color: #3e3e3e;
}

.field__colored {
  display: flex;
  align-items: center;
  padding: 1px;
  border-radius: 15px;
  overflow: hidden;
  font-weight: lighter;
}
.field__colored.blue {
  background-color: #0d1cb3;
}
.field__colored.blue i {
  color: #0d1cb3;
}
.field__colored.pink {
  background-color: #b30d97;
}
.field__colored.pink i {
  color: #b30d97;
}
.field__colored.green {
  background-color: #08ae14;
}
.field__colored.green i {
  color: #08ae14;
}
.field__colored.orange {
  background-color: #ff9100;
}
.field__colored.orange i {
  color: #ff9100;
}
.field__colored.red {
  background-color: #b30d0d;
}
.field__colored.red i {
  color: #b30d0d;
}
.field__colored.black {
  background-color: #000000;
}

.field__colored .field__label {
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  border-radius: 15px;
  padding: 1rem;
}
.field__colored .field__value {
  justify-content: center;
  text-align: center;
  min-width: 30%;
}

.btn__addStat {
  background-color: #f8f5ff;
  padding: 13px;
  font-size: 17px;
  font-style: italic;
  font-weight: bold;
  border: 1px dashed #707070;
}

.search__input {
  border: 1px solid #606060;
  border-radius: 5px;
  padding: 0.5rem;
}
.search__input input {
  border: none;
  height: 100%;
  width: 90%;
  outline: none;
}
.search__input .icon__holder {
  background-color: #757575;
  border-radius: 7px;
  padding: 3px;
  color: #fff;
}

.search__input .icon__holder.blue {
  background-color: #4236e4;
}

.countryList .option i {
  opacity: 0;
  transition: all 0.3s ease;
}
.countryList .option:hover i {
  opacity: 1;
}

.modal__report input[type="date"] {
  min-width: 250px;
}

.chart__holder {
  height: 450px;
}

.logo {
  width: 200px;
  margin: 0 auto;
}

.mobile__nav {
  height: 100vh;
  position: fixed;
  background-color: #fff;
  right: 0;
  z-index: 9999999;
  top: 0;
  padding: 2rem 2.5rem;
  opacity: 0;
  transition: all 0.4s ease;
  visibility: hidden;
}
.backdrop {
  background: transparent;
  position: fixed;
  height: 100vh;
  width: 100%;
}
.mobile__nav.active {
  min-width: 200px;
  opacity: 1;
  visibility: visible;
}
.mobile__icon {
  display: none;
}

.desk__hide {
  display: none !important;
}

.mobile__hide {
  display: block;
}
@media (max-width: 1477px) {
  .search {
    display: none;
  }
  .profile__img {
    margin: 0 auto;
  }
}
@media (max-width: 1276px) {
  .nav__links {
    display: none;
  }
  .mobile__icon {
    display: block;
  }
}
@media (max-width: 1000px) {
  .mobile__hide {
    display: none;
  }
  .desk__hide {
    display: block !important;
  }
  .nav__links {
    min-width: 100%;
    justify-content: center;
  }
  .sideBar {
    width: 0;
    position: fixed;
    opacity: 0;
    transition: all 0.4s ease-out;
    top: 0;
    visibility: hidden;
    z-index: 99999;
  }
  .sideBar.active {
    min-width: 100px;
    opacity: 1;
    visibility: visible;
  }
  .lay__content {
    margin-left: 0;
  }
}
@media (max-width: 713px) {
  .logo {
    width: 150px;
  }
}
