:root {
  --primary-color: #0d1cb3;
}

.auth__body {
  background-color: #f8f9fa;
  min-height: 100vh;
}
.auth__card {
  width: 100%;
  max-width: 480px;
  min-height: 50vh;
  border-radius: 4rem;
  box-shadow: 0px 6px 15px 4px rgb(225 235 238 / 62%);
  border: 1px solid #0000001c;
  background-color: #fff;
  overflow: hidden;
  position: relative;
}
.auth__card form {
  width: 90%;
  margin: 0 auto;
}

.inputHolder {
  border: 1px solid #b7b7b7;
  border-radius: 6px;
  background-color: #f8f9fa;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
}
.inputHolder select,
.inputHolder input,
.inputHolder textarea {
  border: none;
  outline: none;
  height: 100%;
  width: 98%;
  font-size: 17px;
  padding: 1rem;
}
.inputHolder:focus-within {
  border-color: var(--primary-color);
}
.auth__card-head {
  background-image: url("/public/images/authBg.svg");
  height: 250px;
  background-repeat: no-repeat;
  background-position: 30%;
  background-size: cover;
  position: relative;
  width: 100%;
  left: 0;
  top: 0;
}
.auth__card-head .imgHolder {
  max-width: 50%;
  min-height: 15vh;
  margin: 0 auto;
}

@media (max-width: 1400px) {
  .auth__card {
    width: 45%;
  }
}

@media (max-width: 1100px) {
  .auth__card {
    width: 70%;
  }
}

@media (max-width: 767px) {
  .auth__card {
    width: 90%;
  }
}
