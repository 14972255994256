@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap");

:root {
  --primary-color: #0d1cb3;
}

a {
  text-decoration: none;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: "Rubik", sans-serif;
}

button {
  border: none;
}

.transactions td {
  height: 7vh;
  vertical-align: middle;
}

.transactions th {
  font-weight: 300;
}

table td,
table th {
  border: none !important;
  vertical-align: middle;
}

table thead {
  background-color: #f0f2f7;
  border-radius: 12px;
  height: 7vh;
}

.table > thead {
  vertical-align: middle;
}

.fancy.light thead {
  background-color: #4236e4;
}

.fancy thead {
  background-color: #0d1cb3;
  border-radius: 10px;
  color: #fff;
  border: none;
  height: 5vh;
}
.fancy tr {
  transition: all 0.4s ease-in-out;
  height: 6vh;
}
.fancy tr:hover {
  background-color: #f6f6f6;
}

.sectionHeight {
  min-height: 70vh;
}

.sectionHead__mid {
  font-size: 30px;
}

.sectionText {
  font-size: 17px;
}

.sectionText__small {
  font-size: 13px;
}

.sectionText__mid {
  font-size: 21px;
}

.sectionText__big {
  font-size: 24px;
}

.text__primary {
  color: var(--primary-color);
}

.text__mustard {
  color: #ff9100;
}

.text__danger {
  color: #ff0000;
}

.text__green {
  color: #08ae14;
}

.bg__coke {
  background-color: #fff;
}

.bg__blue-light {
  background-color: #f8f9fa;
}

.text__bold {
  font-weight: bold;
}

.text__light {
  font-weight: 300;
}

.text__gray {
  color: #757575;
}

.vAlign {
  text-align: center;
}

.rAlign {
  text-align: right;
}

.isRelative {
  position: relative;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alignOnly {
  display: flex;
  align-items: center;
}

.w-80 {
  width: 80%;
  margin: 0 auto;
}

.clickable {
  cursor: pointer;
}

.hasBorder__top {
  border-top: 1px solid rgba(211, 211, 211, 0.507);
}
.hasBorder__bottom {
  border-bottom: 1px solid rgba(211, 211, 211, 0.507);
}

.container__custom {
  padding: 0 2.5rem 1.5rem;
  /* max-width: 1450px;
  margin: 0 auto; */
}

.btn__default {
  background: linear-gradient(90deg, #0d1cb3 0%, #3eb9e5 110%) 0% 0% no-repeat
    padding-box;
  box-shadow: inset 0px 0px 10px #021697;
  border-radius: 9px;
  color: #fff;
  border: none;
  outline: none;
  font-weight: 300;
  padding: 1rem 2rem;
  font-size: 18px;
}

.btn__default:hover {
  background: linear-gradient(90deg, #3eb9e5 0%, #0d1cb3 110%) 100% 0% no-repeat
    padding-box;
}
.btn__danger {
  background: linear-gradient(90deg, #b30d0d 0%, #e5863e 110%) 0% 0% no-repeat
    padding-box;
  box-shadow: inset 0px 0px 10px #970202;
  border-radius: 9px;
  color: #fff;
  border: none;
  outline: none;
  font-weight: 300;
  padding: 1rem 2rem;
  font-size: 18px;
}

.btn__transparent {
  box-shadow: inset 0px 0px 10px #b7b7b7;
  background-color: transparent;
  border: 1px solid #b7b7b7;
  border-radius: 9px;
  padding: 0.6em 2rem;
  outline: none;
  font-size: 18px;
  font-weight: 300;
  color: #757575;
}

.gradient__linear {
  background: transparent linear-gradient(180deg, #0d1cb3 0%, #3eb9e5 100%) 0%
    0% no-repeat padding-box;
}

.text__white {
  color: #fff;
}

.card__custom {
  box-shadow: 0px 10px 10px #b7b7b740;
  border-radius: 17px;
  padding: 2rem;
  background-color: #fff;
}

.modal__custom .modal-dialog {
  width: 800px;
}

.mobile__hide {
  display: block;
}
.desk__hide {
  display: none;
}

@media (max-width: 1000px) {
  .mobile__hide {
    display: none;
  }
  .desk__hide {
    display: block;
  }
}

@media (max-width: 760px) {
  .sectionText {
    font-size: 15px;
  }
  .sectionHead__mid {
    font-size: 25px;
  }
  .container__custom {
    padding: 1rem;
  }

  .sectionHeight {
    min-height: 45vh;
  }
  .card__custom {
    padding: 1.5em;
  }
}
.file-upload {
  position: relative;
}
.file-upload input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}
